<template>
  <div>
    <table class="table_form line-bin">
      <tbody>
        <div class="sdGrid">
          <esp-dx-data-grid
            :data-grid="dataGrid"
            ref="sdGrid"
            :auto-width="true"
            :selected-row-keys="dataGrid.selectedRowKeys"
          />
        </div>
      </tbody>
    </table>
  </div>
</template>
<script>
  import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid.vue";
  export default {
    components: {
      EspDxDataGrid,
    },
    props: {
      option: {
        default: () => {
          return {
            width: '50%',
            height: '85%',
            minWidth: null,
            minHeight: null,
            saveBtnVisible: false,
            cancelBtnVisible: true,
          };
        },
        type: Object,
      },
      deviceInfo: {
        type: Object,
      },
      sdData: {
        type: Array,
      },
      isOpened: {
        type: Boolean,
      },
      cmList: {
        type: Array,
      },
      idx: {
        type: String,
      },
      authority: {
        type: String,
      },
    },
    watch: {
      async isOpened() {
        if (this.isOpened) {
          await this.selectDataList();
        } else {
          this.resetGridDataSource();
        }
      },
    },
    data() {
      return {
        dataGrid: {
          callApi: 'CALL_EUC_API',
          refName: 'sdGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: true, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          disableTotalCount: true,
          dataSource: [],
          //   width: '400', // 주석처리시 100%
          height: '200', // 주석처리시 100%
          apiActionNm: {
            update: '',
          },
          customEvent: {
            //그리드 컴포넌트의 이벤트를 해당 페이지에서 사용할 수 있도록 처리 [ 사용: true, 미사용: false(생략 가능) ]
            cellPrepared: true,
            saving: true,
            rowUpdating: true,
            rowUpdated: true,
            editorPrepared: true,
          },
          showActionButtons: {
            excel: false, // 엑셀 버튼
            customButtons: [],
          },
          isDuplicateConfigKey: false, //설정키 중복 체크
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: false, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'single', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: false, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'cell', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'single', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '유형',
              i18n: 'UC.WORD.TYPE',
              dataField: 'field',
              width: 80,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowSorting: false,
              allowHeaderFiltering: false,
              allowGrouping: false,
              cellTemplate: async (container, options) => {
                const imgTag = document.createElement('img');
                const imgData = this.$_getAttachFileURL(null, options.value);
                imgTag.setAttribute('src', imgData);
                container.append(imgTag);
              },
            },
            {
              caption: '전화번호',
              i18n: 'UC.WORD.NUMBER',
              dataField: 'number',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowSorting: false,
              allowHeaderFiltering: false,
              allowGrouping: false,
              calculateDisplayCellValue: rowData => {
                if (rowData?.number) {
                  return rowData.number;
                }
                if (rowData.number == null || rowData.number == 'null') {
                  return '';
                }
              },
              validationRules: [
                {
                  type: 'custom',
                  validationCallback: function (e) {
                    if (e.value === '') {
                      return true;
                    }
                    return !isNaN(e.value) && e.value !== null && e.value !== '';
                  },
                  message: this.$_msgContents('UC.MESSAGE.NUMBER_ENTER'),
                },
              ],
            },

            {
              caption: '레이블',
              i18n: 'UC.WORD.LABEL',
              dataField: 'label',
              //   width: 150,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: true,
              sortOrder: 'none', // acs desc none
              allowSorting: false,
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
              calculateDisplayCellValue: rowData => {
                if (rowData?.label) {
                  return rowData.label;
                }
                if (rowData.label == null || rowData.label == 'null') {
                  return '';
                }
              },
            },
          ],
        },
        stylingMode: 'outlined', //outlined, underlined, filled
        searchType: {
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          searchTexts: {
            value: this.pickupKeyword,
          },
          customTypes: {
            cm: null,
            model: null,
            status: null,
          },
          paramsData: null,
        },
      };
    },
    computed: {},
    methods: {
      /**@description : 라이프사이클 created시 호출되는 메서드 */
      createdData() {
        this.dataGrid.showActionButtons.customButtons = this.getCustomButtons();
      },
      /**@description : 라이프사이클 mounted시 호출되는 메서드 */
      async mountedData() {},
      getCustomButtons() {
        const buttons = [
          {
            widget: 'dxButton',
            options: {
              icon: '',
              text: this.$_msgContents('UC.WORD.APPLY'),
              elementAttr: { class: 'btn_XS default filled ' },
              //   width: 60,
              height: 30,
              onClick: () => {
                this.onSetSd();
              },
            },
            location: 'after',
          },
        ];
        return buttons;
      },
      async selectDataList() {
        this.dataGrid.dataSource = this.sdData;
      },
      /** @description 그리드 데이터 초기화 */
      resetGridDataSource() {
        this.dataGrid.dataSource = [];
        this.$refs.sdGrid.clearSelection();
      },
      /**@description : 적용버튼 클릭 */
      async onSetSd() {
        if (this.$refs.sdGrid.hasEditData()) {
          await this.$_Msg('수정중인 항목이있습니다. ');
          return false;
        }

        const confirm = this.$_msgContents('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' });
        if (!(await this.$_Confirm(confirm))) {
          return;
        }
        const gridData = this.dataGrid.dataSource.map(d => {
          if (d.number !== null && d.number !== '' && (d.label === null || d.label === '')) {
            d.label = d.number;
          }
          return { ...d };
        });
        if (this.authority == 'manager') {
          this.$emit('closeSd', null, gridData, 'sd');
        } else {
          this.$emit('closeSd', gridData, this.idx);
        }
      },
      /** @description 교환기 이름 한글 변환 메서드
       *  @param {String} cm Name
       *  @returns {String} 한글명
       */
      getCmNm(cm) {
        let cmNm = '';
        for (let i = 0; i < this.cmList.length; i++) {
          if (this.cmList[i].cmNm === cm) {
            cmNm = this.cmList[i].description;
          }
        }
        if (cmNm !== '' && cmNm !== null) {
          return cmNm;
        } else {
          return '';
        }
      },
    },
    async created() {
      this.createdData();
    },
    async mounted() {
      await this.mountedData();
    },
  };
</script>
